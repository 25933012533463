import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { HttpConfigService } from '../../core/httpConfig/httpConfigService';
import { IStore } from '../../appInterfaces/IStore';
import { DesignUtilityServices } from '../design-utility.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  api: string;
  storeList = new BehaviorSubject<IStore[] | null>(null);
  selectedStore = new BehaviorSubject<IStore | null>(null);

  constructor(
    private apiConfig: HttpConfigService,
    private _du: DesignUtilityServices,
  ) {
    this.api = this.apiConfig.getBaseUrl;
  }

  getAllStoresFromAPI(): Observable<any> {
    return this.apiConfig.get(`${this.api}store`);
  }

  async setupStore(): Promise<void> {
    if (this.storeList.value) return;

    this.getAllStoresFromAPI().subscribe(
      (res: IStore[]) => {
        this.setStoreList(res);
        if (res.length === 1) {
          this.setSelectedStore(res[0]);
        }
      },
      (err) => {
        this._du.openCustomSnackBar("Error: " + err, 'Close', 'error');
      }
    );
  }

  setStoreList(storeList: IStore[]) {
    this.storeList.next(storeList);
  }

  setSelectedStore(store: IStore) {
    this.selectedStore.next(store);
  }

  ensureStoreSelected(): Observable<IStore> {
    return this.selectedStore.asObservable().pipe(
      filter(store => !!store)
    );
  }


  /**
   * Get Stripe account by store id
   * @param storeId 
   * @returns 
   */
  getStripeAcByStoreId(storeId:number): Observable<any> {
    return this.apiConfig.get(this.api + "store/stripe/" +storeId);
  }

  clearSelectedStore(): void {
    this.selectedStore.next(null);
  }
}
