@if(Form){
  <div class="authForm-wrapper">
    <figure class="img-icon">
      <img width="327" height="273" src="/images/welcome-graphic.svg" alt="">
    </figure>

    <h3>Welcome User</h3>
    <form [formGroup]="Form" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="outline" class="username-fld full-width">
        <mat-label>{{(usernameType==='mobile')? 'Mobile' : (usernameType==='email')? 'Email' : 'Email or Mobile'
          }}</mat-label>

        <input [class.pl-3]="usernameType==='mobile'" matInput placeholder="Enter email or mobile number" type="text"
          formControlName="username" (ngModelChange)="changeInputType()">
        @if(usernameType==='mobile'){
        <span matPrefix class="numPrefix">+1</span>
        }
        <mat-icon matSuffix>{{(usernameType==='email')? 'email' : (usernameType==='mobile') ? 'call' : ''}}</mat-icon>
      </mat-form-field>

      <button mat-flat-button class="btn full-width" [disabled]="!Form.valid || loading">
        <span [class.vHidden]="loading">Next</span>
        @if(loading){
        <mat-spinner color="primary" diameter="20"></mat-spinner>
        }
      </button>
    </form>

  </div>
}