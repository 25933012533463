import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import { DesignUtilityServices } from '../../../appServices/design-utility.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { OtpFieldComponent } from '../../otp-field/otp-field.component';
import { AuthService } from '../../../appServices/auth/auth.service';
import { environment } from '../../../../environments/environment';
import { IBasicInfo } from '../../../appInterfaces/IBasicInfo';
import { BasicInfoService } from '../../../appServices/basic-info/basic-info.service';

@Component({
  selector: 'app-login-by-password',
  standalone: true,
  imports: [
    OtpFieldComponent,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './login-by-password.component.html',
  styleUrl: './login-by-password.component.scss'
})
export class LoginByPasswordComponent implements OnInit {
  Form!: FormGroup;
  usernameType: any = '';
  validator:any;
  loading:boolean = false;
  loading2:boolean = false;
  hide : boolean = true;
  S3_URL = environment.S3_URL;
  basicInfo!: IBasicInfo | null;
  

  constructor(
    private _fb: FormBuilder,
    private authService: AuthService,
    private basicInfoService: BasicInfoService,
    private _du: DesignUtilityServices
  ){
    this.validator = this._du.validator;
  }

  ngOnInit(): void {
    this.getBasicInfo();
    this.initForm();
  }



  getBasicInfo(): void {
    this.basicInfoService.basicInfo.subscribe(
      (res) => {
        this.basicInfo = res;
      }
    );
  }
  

  /**
   * init
   */
  initForm() {
    this.Form = this._fb.group({
      password: ['', [Validators.required]],
    });
  }
  

  // onSubmits(){
  //   this.loading = true;
  //   setTimeout(() => {
  //     this.loading = false;
  //     this._du.authStep.next('login-by-otp');
  //     
  //   }, 1000);
  // }


  onSubmit(){
    this.loading = true;
    if (this.Form.invalid) {
      return;
    }

    const data ={
      userName: this.authService.username.username,
      password:this.Form.value.password,
      userRoleModel: {
        userRole :"customer"
      },
      asOTP:false
    };

    this.authService.login(data).subscribe(
    (res:any) =>{
      this.loading = false;
      this.authService.postLogin(res.token);
    },
    (err:any) =>{
      this.loading = false;
      this._du.openCustomSnackBar('Error: '+err, 'Close', 'error');
    });
  }


  forgetPassword(){
    this.authService.updateAuthStep('forget-password');
  }


  /**
   * send Otp
   */
  sendOtp(){
    this.loading2 = true;

    const payload = {
      mobileNumber: '',
      emailId: '',
    };

    const emailRegex = RegExp(this.validator.email);
    if(emailRegex.test(this.authService.username.username)){
      // User entered is email
      payload.emailId = this.authService.username.username;
    }else{
      payload.mobileNumber = this.authService.username.username;
    }

    this.authService.sendOtp(payload).subscribe(
    (res) =>{
      this.loading2 = false;
      this.authService.updateAuthStep('login-by-otp');
    },
    (err) =>{
      this.loading2 = false;
      this._du.openCustomSnackBar('Error: '+err, 'Close', 'error');
    });
  }


}
