import { Component, EventEmitter, Output, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { DesignUtilityServices } from '../../appServices/design-utility.service';

@Component({
  selector: 'app-otp-field',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './otp-field.component.html',
  styleUrl: './otp-field.component.scss'
})
export class OtpFieldComponent {
  @Output() otpSubmit = new EventEmitter();
  
  Form: FormGroup;
  formInput = ['code1', 'code2', 'code3', 'code4'];
  @ViewChildren('formRow') rows: any
  validator:any;


  constructor(
    private _du: DesignUtilityServices
  ) {
    this.validator = this._du.validator;
    this.Form = this.toFormGroup(this.formInput);
  }

  toFormGroup(elements:any) {
    const group: any = {};

    elements.forEach((key:any) => {
      group[key] = new FormControl('', [Validators.required, Validators.pattern(this.validator.otp)]);
    });
    return new FormGroup(group);
  }

  keyUpEvent(event:any, index:number) {
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1 ;
    } else {
      let charCode = (event.which) ? event.which : event.keyCode;
      // Only Numbers 0-9
      const condition1 = !(charCode < 96 || charCode > 105);
      const condition2 = !(charCode < 48 || charCode > 57);
      const condition3 = charCode == 229;
      if (condition1 || condition2 || condition3) {
        event.preventDefault();
        pos = index + 1;
      }
    }
    if (pos > -1 && pos < this.formInput.length ) {
      this.rows._results[pos].nativeElement.focus();
    }
    this.onSubmit();
    
  }

  paste(event:any) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    this.Form.setValue({
      code1: pastedText.charAt(0),
      code2: pastedText.charAt(1),
      code3: pastedText.charAt(2),
      code4: pastedText.charAt(3)
    });
    this.onSubmit();
  }

  onSubmit() {
    if (this.Form.invalid) {
      this.otpSubmit.emit('INVALID');
      return;
    }
    let otp = Object.keys(this.Form.value).map(field => this.Form.value[field]).join('');
    this.otpSubmit.emit(otp);
  }
}
