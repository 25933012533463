@if(Form){
  <div class="authForm-wrapper">
    <figure class="restaurant-logo">
      @if (basicInfo && basicInfo.imageLink) {
        <img [src]="S3_URL + basicInfo.bucketName + '/' + basicInfo.imageLink" class="img" alt="Logo">
      } @else if (basicInfo && basicInfo.darkLogo) {
          <img [src]="S3_URL + basicInfo.bucketName + '/' + basicInfo.darkLogo" class="img" alt="Logo">
      }
    </figure>

    <h3>OTP Login</h3>
    <p>Check your email to get 4 digit OTP pin to login.</p>
    <form [formGroup]="Form" (ngSubmit)="onSubmit()">
      <app-otp-field (otpSubmit)="getOtp($event)"></app-otp-field>

      <button mat-flat-button class="btn full-width" 
      [disabled]="!Form.valid || loading"
      >
        <span [class.vHidden]="loading">Login</span>
        @if(loading){
        <mat-spinner color="primary" diameter="20"></mat-spinner>
        }
      </button>
      <div class="secondary-action">
        Didn`t receive code? <a class="accent" (click)="sendOtp()">Request Again</a>
      </div>
      <button mat-flat-button type="button" class="btn-outline full-width" (click)="loginWithPassword()"
      >
        <span>Login with Password</span>
      </button>
    </form>

  </div>
}