import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import { DesignUtilityServices } from '../../../appServices/design-utility.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { OtpFieldComponent } from '../../otp-field/otp-field.component';
import { AuthService } from '../../../appServices/auth/auth.service';
import { environment } from '../../../../environments/environment';
import { IBasicInfo } from '../../../appInterfaces/IBasicInfo';
import { BasicInfoService } from '../../../appServices/basic-info/basic-info.service';

@Component({
  selector: 'app-login-by-otp',
  standalone: true,
  imports: [
    OtpFieldComponent,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './login-by-otp.component.html',
  styleUrl: './login-by-otp.component.scss'
})
export class LoginByOtpComponent implements OnInit {
  Form!: FormGroup;
  usernameType: any = '';
  validator:any;
  loading:boolean = false;
  hide : boolean = true;
  S3_URL = environment.S3_URL;
  basicInfo!: IBasicInfo | null;
  

  constructor(
    private _fb: FormBuilder,
    private _du: DesignUtilityServices,
    private basicInfoService: BasicInfoService,
    private authService: AuthService
  ){
    this.validator = this._du.validator;
  }

  ngOnInit(): void {
    this.initForm();
    this.getBasicInfo();
  }


  getBasicInfo(): void {
    this.basicInfoService.basicInfo.subscribe(
      (res) => {
        this.basicInfo = res;
      }
    );
  }
  

  /**
   * init
   */
  initForm() {
    this.Form = this._fb.group({
      otp: ['', [Validators.required, Validators.minLength(4),Validators.pattern(this.validator.otp)]],
    });
  }

  getOtp(otp:any){
    this.Form?.get('otp')?.patchValue(otp);
  }



  loginWithPassword(){
    this.authService.updateAuthStep('login-by-password');
  }


  onSubmit(){
    if (this.Form.invalid) {
      return;
    }
    this.loading = true;

    const data ={
      userName: this.authService.username.username,
      password:this.Form.value.otp,
      userRoleModel: {
        userRole :"customer"
      },
      asOTP:true
    };


    this.authService.login(data).subscribe( 
    (res:any) =>{
      this.loading = false;
      this.authService.postLogin(res.token);
      this._du.openCustomSnackBar('Welcome back!', 'Close', 'success');
    },
    (err:any) =>{
      this.loading = false;
      this._du.openCustomSnackBar("Invalid otp", 'Close', 'error');
    });
  }


  /**
   * send Otp
   */
  sendOtp(){
    this.loading = true;

    const payload = {
      mobileNumber: '',
      emailId: '',
    };

    const usernameValue = this.Form.get('userName')?.value;
    if (this.usernameType === 'mobile') {
      payload.mobileNumber = usernameValue;
    } else {
      payload.emailId = usernameValue;
    }
    this.authService.sendOtp(payload).subscribe(
    (res) =>{
      this.loading = false;
    },
    (err) =>{
      this.loading = false;
      this._du.openCustomSnackBar('Error: '+err, 'Close', 'error');
    });
  }


}
