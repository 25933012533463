<div class="leaderboard-wrapper">
    <h4 class="mb-3">JOIN THE LEADERBOARD</h4>
    <button (click)="onNoClick(null)" mat-icon-button class="close">
        <mat-icon>close</mat-icon>
    </button>
    <br>
    <p>Join the leaderboard programm and get Free 250 🏆 Loyalty Points</p>
    <form [formGroup]="Form" (ngSubmit)="onSubmit()">
        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Gamer Tag</mat-label>
            <input matInput placeholder="Enter gamer tag" formControlName="gamertag" />
        </mat-form-field>
      <div class="mb-1">
        <button
          mat-flat-button
          color="primary"
          [disabled]="!Form.valid || loading"
        >
          @if (!loading) {
            <ng-container>Submit</ng-container>
          }
          @if (loading) {
            <mat-spinner diameter="20"></mat-spinner>
          }
        </button>
      </div>
    </form>
</div>  