import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpConfigService } from '../../core/httpConfig/httpConfigService';
import { IBasicInfo } from '../../appInterfaces/IBasicInfo';
import { LocalStorageService } from '../localstorage/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class BasicInfoService {
  api: string;
  basicInfo = new BehaviorSubject<IBasicInfo | null>(null);

  constructor(
    private apiConfig: HttpConfigService,
    private localStorageService: LocalStorageService
  ) {
    this.api = this.apiConfig.getBaseUrl + "account-settings";
  }

  getBasicInfoFromAPI(): Observable<IBasicInfo> {
    return this.apiConfig.get(this.api);
  }


  /*------------------------------------------------------------------
    Basic Info (Resolver code)
  -------------------------------------------------------------------*/

  /**
     * get basic info
     */
  public getBasicInfo(forecGet?: boolean) {
    const tenantName = this.localStorageService.getItem('tenant') ?? '';
    if (!tenantName) {
      return;
    }

    const basicInfo = this.localStorageService.getItem("basic-info");

    if (basicInfo) {
      const parsedInfo = JSON.parse(basicInfo);
      if (forecGet) {
        this.getBasicInfoFromAPI().subscribe(res => {
          this.setBasicInfo(res);
        });
      }else{
        this.basicInfo.next(parsedInfo);
      }
    }else{
      this.getBasicInfoFromAPI().subscribe(res => {
        this.setBasicInfo(res);
      });
    }
  }

  

  public setBasicInfo(basicInfo: IBasicInfo) {
    this.localStorageService.setItem("basic-info", JSON.stringify(basicInfo));
    this.basicInfo.next(basicInfo);
  }

  /**
   * clear basic info
   */
  public clearBasicInfo() {
    this.basicInfo.next(null);
    this.localStorageService.removeItem('basic-info');
  }


}
