@if(storeList && storeList.length > 1){
  <div class="dialog-wrapper">
        <!-- <button (click)="onNoClick()" mat-icon-button class="close">
            <mat-icon>close</mat-icon>
        </button> -->

        <h2 class="align-center">
            <mat-icon color="primary">storefront</mat-icon>
             Choose your Store
        </h2>


        <div class="store-list custom-scrollbar">
            @if(storeList && storeList.length> 1 ){
                @for (store of storeList; track $index) {
                    <mat-card class="store-box bg-app mat-elevation-z0">
                        <figure>
                            <img [src]="S3_URL+''+basicInfo?.bucketName+'/'+store?.imageLink" alt="Store Image">
                        </figure>
                        <h3>{{store.storeName}}</h3>
                        <a class="address foreground" [attr.href]="'http://maps.google.com/?q='+store.address" target="_blank">
                            <mat-icon color="primary">share_location</mat-icon>
                            <span class="txt">{{store.address}}</span>
                        </a>
                        @if(store.active){
                            <button class="btn cta" mat-stroked-button color="primary" (click)="onSelectStore(store)">
                                <mat-icon>ads_click</mat-icon>
                                Select this Store
                            </button>
                        }@else {
                            <button class="btn cta" mat-stroked-button disabled>
                                <mat-icon>ads_click</mat-icon>
                                Opening Soon
                            </button>
                        }
                    </mat-card>
                }
            }@else {
                @for (item of [].constructor(2); track $index) {
                    <div class="skeleton"></div>
                }
            }
        </div>
    </div>
}
