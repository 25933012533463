<form (ngSubmit)="onSubmit()" [formGroup]="Form">
    <div class="otp-field">
        @for (input of formInput; track $index) {
            <input #formRow type="text" formControlName="{{input}}" class="cus-input dark" maxlength="1" 
            (keyup)="keyUpEvent($event, $index)"
            [class.error]="Form.controls[input].hasError('pattern')"
            (paste)="paste($event)">
        }
    </div>
    <!-- <button type="submit" class="btn btn-primary">Submit</button> -->
</form>