import { inject, Injectable } from "@angular/core";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from "../../includes/utilities/custom-snackbar/custom-snackbar.component";

@Injectable({
  providedIn: 'root'
})
export   
 class SnackbarService { 

  private _snackBar = inject(MatSnackBar);
  private horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  private durationInSeconds = 2;
  // private durationInSeconds = 20;

  constructor() {
  }

  successMessage(message:string, action:string ) {
    this._snackBar.openFromComponent(CustomSnackbarComponent, {
      data: {
        message,
        action,
        snackType: 'Success',
        snackBar: this._snackBar
      },
      duration: this.durationInSeconds * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['success-snackbar']
    });
  }

  errorMessage(message:string, action:string) {
    this._snackBar.openFromComponent(CustomSnackbarComponent, {
      data: {
        message,
        action,
        snackType: 'Error',
        snackBar: this._snackBar
      },
      duration: this.durationInSeconds * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['error-snackbar']
    });
  }

  infoMessage(message:string, action:string) {
    this._snackBar.openFromComponent(CustomSnackbarComponent, {
      data: {
        message,
        action,
        snackType: 'Info',
        snackBar: this._snackBar
      },
      duration: this.durationInSeconds * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['info-snackbar']
    });
  }
 
  
}