@if(Form){
  <div class="authForm-wrapper">
    <figure class="restaurant-logo">
      @if (basicInfo && basicInfo.imageLink) {
        <img [src]="S3_URL + basicInfo.bucketName + '/' + basicInfo.imageLink" class="img" alt="Logo">
      } @else if (basicInfo && basicInfo.darkLogo) {
          <img [src]="S3_URL + basicInfo.bucketName + '/' + basicInfo.darkLogo" class="img" alt="Logo">
      }
    </figure>

    <h3>Enter Password</h3>
    <p>Enter your password or login with OTP to continue.</p>
    <form [formGroup]="Form" (ngSubmit)="onSubmit()">
      <!-- <app-otp-field (otpSubmit)="getOtp($event)"></app-otp-field> -->

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Enter your password</mat-label>
        <input matInput 
        [type]="hide ? 'password' : 'text'" formControlName="password" autocomplete="new-password">
        <button mat-icon-button matSuffix type="button"
        (click)="hide = !hide" 
        [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon class="material-icons-outlined">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>

      <!-- [disabled]="!Form.valid || loading" -->
      <button mat-flat-button class="btn full-width" 
      >
        <span [class.vHidden]="loading">Login</span>
        @if(loading){
        <mat-spinner color="primary" diameter="20"></mat-spinner>
        }
      </button>
      <div class="secondary-action">
        Forgot Password? <a (click)="forgetPassword()">Retrive here</a>
      </div>
      <button mat-flat-button type="button" class="btn-outline full-width" (click)="sendOtp()" [disabled]="loading2"
      >
        <span [class.vHidden]="loading2">Login with OTP</span>
        @if(loading2){
        <mat-spinner color="primary" diameter="20"></mat-spinner>
        }
      </button>
    </form>

  </div>
}