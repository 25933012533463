import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthComponent } from '../includes/auth/auth.component';
import { SelectStoreComponent } from '../includes/select-store/select-store.component';
import { SnackbarService } from './snackbar/snackbar.service';
import { Observable } from 'rxjs';
import { JoinLeaderboardComponent } from '../includes/popups/join-leaderboard/join-leaderboard.component';
import { PromptComponent } from '../includes/popups/prompt/prompt.component';

@Injectable({
  providedIn: 'root'
})
export class DesignUtilityServices {
  /**
   * Form Vaidators
   */
  validator = {
    // mobile: '^((\\+91-?)|0)?[0-9]{11}$', // For prod
    mobile: '^((\\+91-?)|0)?([0-9]{10})$', // For preprod
    mobile10And11: /^((\+91-?)|0)?([0-9]{10}|[0-9]{11})$/,
    // email: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
    email: '^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([A-Za-z]{2,9}(?:\\.[A-Za-z]{2,9})?)$',
    otp: '^[0-9]*$',
    pin: '^[0-9]*$',
    price: '',
    noWhiteSpace: '\S'
  }



  constructor(
    public dialog: MatDialog,
    private snackbarService: SnackbarService
    ) {
  }

   

  //------------------------------------------//
  // Custom SnakeBar                          //
  //------------------------------------------//

  // openSnackBar(message: string, action: string, duration: number, className: string): void {
  //   this.snackBar.open(message, action, {
  //     duration: duration * 1000,
  //     panelClass: [className]
  //   });
  // }

  openCustomSnackBar(message: string, action: string, type: string) {
    if (type === 'success') {
      this.snackbarService.successMessage(message, action);
    }
    if (type === 'info') {
      this.snackbarService.infoMessage(message, action);
    }
    if (type === 'error') {
      this.snackbarService.errorMessage(message, action);
    }
  }


  openAuthModal(selectedPlan?: any | null): void {
    const enterAnimationDuration: string = '500ms';
    const exitAnimationDuration: string = '500ms';

    this.dialog.open(AuthComponent, {
      width: '450px',
      data: { selectedPlan },
      enterAnimationDuration,
      exitAnimationDuration,
      panelClass:'dialog-dark'
    });
  }



  openStoreModel(): void {
    const enterAnimationDuration: string = '500ms';
    const exitAnimationDuration: string = '500ms';

    this.dialog.open(SelectStoreComponent, {
      // width: '450px',
      // maxWidth: '85%',
      enterAnimationDuration,
      exitAnimationDuration,
      panelClass:'dialog-dark'
    });
  }


  joinLeaderboard(): Observable<any> {
    const enterAnimationDuration: string = '500ms';
    const exitAnimationDuration: string = '500ms';

    const dialogRef = this.dialog.open(JoinLeaderboardComponent, {
      enterAnimationDuration,
      exitAnimationDuration,
      maxWidth: '500px',
      width: '90%',
      disableClose: true
    });
    return dialogRef.afterClosed()
  }

  

  //------------------------------------------//
  // Custom Prompt                            //
  //------------------------------------------//
  openPrompt(promptData:any): Observable<any> {
    const enterAnimationDuration: string = '500ms';
    const exitAnimationDuration: string = '500ms';
    
    const dialogRef = this.dialog.open(PromptComponent, {
      data: promptData,
      maxWidth: '400px',
      width: '90%',
      disableClose: true,
      enterAnimationDuration,
      exitAnimationDuration,
      panelClass:'dialog-dark'
    });

    return dialogRef.afterClosed();
  }
  

}
