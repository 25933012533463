import { Component, OnInit } from '@angular/core';
import { StoreService } from '../../appServices/store/store.service';
import { DesignUtilityServices } from '../../appServices/design-utility.service';
import { IStore } from '../../appInterfaces/IStore';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import { environment } from '../../../environments/environment';
import { IBasicInfo } from '../../appInterfaces/IBasicInfo';
import { BasicInfoService } from '../../appServices/basic-info/basic-info.service';
@Component({
  selector: 'app-select-store',
  standalone: true,
  imports: [
    MatIconModule,
    MatCardModule
  ],
  templateUrl: './select-store.component.html',
  styleUrl: './select-store.component.scss'
})
export class SelectStoreComponent implements OnInit {
  S3_URL = environment.S3_URL;
  basicInfo!: IBasicInfo|null;
  storeList!: IStore[]|null;

  constructor(
    private _du:DesignUtilityServices,
    public dialogRef: MatDialogRef<SelectStoreComponent>,
    private storeService:StoreService,
    private basicInfoService:BasicInfoService,
  ) {
  }

  ngOnInit(): void {
    this.getBasicInfo();
    this.subscribeStoreList();
  }


   
  /**
  * get basic info
  */
  getBasicInfo(): void {
    this.basicInfoService.basicInfo.subscribe(
      (res) => {
        this.basicInfo = res;
      }
    );
  }



  /**
   * Subscribe store list
   */
  subscribeStoreList() {
    this.storeService.storeList.subscribe(
      (res) => {
        this.storeList = res;
        console.log('storeList=> ',this.storeList);
        if(this.storeList?.length === 1 && this.storeList[0]?.active) {
          this.onSelectStore(this.storeList[0]);
        }
      }
    );
  }

  /**
   * on select store
   * @param store 
   */
  onSelectStore(store: IStore) {
    this.storeService.setSelectedStore(store);
    // this.deliveryTypeStorage.getDeliveryTypesFromAPI(store.id);
    // this.multipleStore.emit(this.stores.length > 1 ? true : false);
    this.onNoClick();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
