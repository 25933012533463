import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IUser } from '../../../appInterfaces/IUser';
import { ProfileService } from '../../../appServices/profile/profile.service';
import { TokenService } from '../../../appServices/token/token.service';
import { MatDialogRef } from '@angular/material/dialog';
import { IProfile } from '../../../appInterfaces/IProfile';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-join-leaderboard',
  standalone: true,
  imports: [
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule, 
    MatProgressSpinnerModule,
    MatButtonModule,

  ],
  templateUrl: './join-leaderboard.component.html',
  styleUrl: './join-leaderboard.component.scss'
})
export class JoinLeaderboardComponent implements OnInit {

  Form!: FormGroup;
  loading: boolean = false;
  user!: IProfile;

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    public dialogRef: MatDialogRef<JoinLeaderboardComponent>
  ) { 
  }
  
  ngOnInit(): void {
    this.Form = this.fb.group({
      gamertag: ['', [Validators.required]]
    });
    this.subscribeLoggedInUser();
  }

  

  /**
   * Subscribe User
   */
  subscribeLoggedInUser(): void {
    this.profileService.profile.subscribe(
      (res) =>{
        if(res){
          this.user = res;
          this.Form.get('gamertag')?.patchValue(this.user.gamertag);
        }
      }
    );
  }


  onNoClick(id: number|null): void {
    this.dialogRef.close(id);
  }

  /**
   * add menu
   */
  onSubmit() {
    if (this.Form.invalid) {
      return;
    }
    this.loading = true;
    const payload = {
      ...this.user,
      gamertag:this.Form.value.gamertag
    }

    this.profileService.updateProfile(payload).subscribe(
      (res) => {
        // this.toasterMessage.successMessage('Gamer tag added successfully.');
        this.getUserProfile();
        this.loading = false;     
        this.dialogRef.close();
      }, err => {
        // this.toasterMessage.errorMessage('Profile update failed.');
        this.loading = false;
      }
    );
  }

  getUserProfile(){
    this.profileService.getProfile();
  }

}
